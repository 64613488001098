.container{
    display: flex;
    flex-direction: row;
    width: 100vw; 
    height: 100vh;
}

.leftContent{
    flex:6;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.leftImg{
    max-height: 100%;
    max-height: 100%;
}
.rightContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    flex:4;
}



