.ill-config-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.ill-config-search {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ill-config-online {
    margin: 80px;
    right: 0;
    top: 0;
    position: absolute;
}