.storage-in-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    height: 100%;
}


.storage-in-out-container {
    display: flex;
    flex-direction: column;
}

.storage-in-out-container-search-bar {
    display: flex;
    flex-direction: row;
}