.yiliaoqixie-details-config-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    height: 100%;
}


