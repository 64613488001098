.product-config-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    height: 100%;
}

.product-config-search {}

.product-config-online {
    margin: 80px;
    right: 0;
    top: 0;
    position: absolute;
}